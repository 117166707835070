import Swiper from "swiper/bundle";
import 'swiper/css';

export const filterVersionsBySerie = () => {
    document.addEventListener('DOMContentLoaded', function () {
        const serieControl = document.querySelector("#serie-selected");
        const versionSelect = document.querySelector("#versions-container");
        const versionOptions = document.querySelectorAll("#versions-select .option");
        const versionControl = document.querySelector("#version-selected");
        const versionPlaceholder = versionSelect ? versionSelect.querySelector(".value-placeholder") : null;
        const img = document.querySelector(".img-vehicle");
        const maintenanceAccordion = document.querySelector(".maintenance_page_bottom__accordion");
        maintenanceAccordion.style.pointerEvents = "none";


        // Cambio de serie
        if (serieControl) {
            serieControl.addEventListener("value-change", () => {
                const selectedSerieId = serieControl.value;

                if (!selectedSerieId) {
                    versionOptions.forEach((option) => {
                        option.style.display = "none";
                    });
                    // Restablecer
                    versionControl.value = "";
                    versionPlaceholder.innerText = "Select version";
                    versionSelect.classList.remove("--disabled");
                    return;
                }

                versionOptions.forEach((option) => {
                    if (option.dataset.serie === selectedSerieId) {
                        option.style.display = "block";
                    } else {
                        option.style.display = "none";
                    }
                });

                // Restablecer
                versionControl.value = "";
                versionPlaceholder.innerText = "Select version";
                versionSelect.classList.remove("--disabled");
                versionSelect
                    .querySelectorAll(".option.--selected")
                    .forEach((opt) => opt.classList.remove("--selected"));
            });
        }

        // Cambio de version
        if (versionControl) {
            versionControl.addEventListener("value-change", () => {
                const versionId = versionControl.value;
                const swiperWrapper = document.querySelector("#maintenance-kms-swiper");
                const todoDescription = document.querySelector("#description-todo");
                const inspectDescription = document.querySelector("#description-inspect");
                const changeDescription = document.querySelector("#description-change");
                const maintenancePrice = document.querySelector("#maintenance-price");
                const maintenanceBuyButton = document.querySelector("#maintenance-price-box").querySelector("a");
                const maintenanceVersion = document.querySelector("#maintenance-version");
                const maintenanceImg = document.querySelector("#maintenance-img");

                // Restablecer
                swiperWrapper.innerHTML = "";
                todoDescription.innerHTML = "";
                inspectDescription.innerHTML = "";
                changeDescription.innerHTML = "";   
                maintenancePrice.innerHTML = "";

                // Obtención mantenciones
                if (versionId) {
                    const maintenancesURL = `/api/store/maintenance_plans`
                    fetch(maintenancesURL, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        const idMaintenance = data.find((option) => String(option.version.id) === String(versionId));

                        if (!idMaintenance) {
                            maintenanceVersion.style.textTransform = "none";
                            maintenanceVersion.innerText = `No se encontraron mantenciones para esta versión.`;
                            maintenanceImg.src = "/static/website/images/maintenance_page_default.png";
                            maintenanceBuyButton.href = "";
                            maintenanceAccordion.style.pointerEvents = "none";
                            maintenanceAccordion.querySelectorAll('.accordion').forEach((accordion) => {
                                accordion.classList.remove('active');
                                const panel = accordion.nextElementSibling;
                                panel.style.display = "none";
                                const plus = accordion.querySelector('.toggle-plus')
                                const minus = accordion.querySelector('.toggle-minus')
                                plus.style.display = "block";
                                minus.style.display = "none";
                            })
                        } else {
                            const apiUrl = `/api/store/maintenance_plans/${idMaintenance.id}`;
                            fetch(apiUrl, {
                                method: "GET",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            })
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error(`HTTP error! Status: ${response.status}`);
                                }
                                return response.json();
                            })
                            .then(data => {
                                const swiperWrapper = document.querySelector("#maintenance-kms-swiper");
                                const articles = swiperWrapper.querySelectorAll('.swiper-slide');
                                articles.forEach((article) => {
                                    article.remove();
                                })
                                maintenanceAccordion.style.pointerEvents = "auto";
                                maintenanceBuyButton.href = `/es/tienda/#/mantenciones/${data.id}`;

                                // Restablecer
                                swiperWrapper.innerHTML = "";
                                todoDescription.innerHTML = "";
                                inspectDescription.innerHTML = "";
                                changeDescription.innerHTML = "";   
                                maintenancePrice.innerHTML = "";
            
                                // Carga de cada opcion de mantenciones
                                data.options.forEach(option => {
                                    const article = document.createElement("article");
                                    article.classList.add("swiper-slide");
                                    article.id = option.id;
            
                                    article.dataset.toDo = option.to_do || "-";
                                    article.dataset.inspect = option.inspect || "-";
                                    article.dataset.change = option.change || "-";
                                    article.dataset.price = option.price.toLocaleString("es-CL", { maximumFractionDigits: 0 });
            
                                    const paragraph = document.createElement("p");
                                    paragraph.textContent = `${option.kilometers.toLocaleString("es-CL")}  KM`;
                                    
                                    article.appendChild(paragraph);
                                    swiperWrapper.appendChild(article);
                                });                            
            
                                // Cargar datos iniciales
                                document.querySelector(".maintenance_swiper").querySelector(".swiper-button-next").style.display = "flex";
                                document.querySelector(".maintenance_swiper").querySelector(".swiper-button-prev").style.display = "flex";
                                todoDescription.innerHTML = data.options.length != 0 ? data.options[0].to_do : "-";
                                inspectDescription.innerHTML = data.options.length != 0 ? data.options[0].inspect : "-";
                                changeDescription.innerHTML = data.options.length != 0 ? data.options[0].change : "-";
                                maintenancePrice.innerHTML = data.options.length != 0 ? Number(data.options[0].price).toLocaleString("es-CL", { maximumFractionDigits: 0 }) : "-";
                                maintenanceVersion.style.textTransform = "uppercase";
                                maintenanceVersion.innerText = `Lexus ${data.version.name}`;
                                img.src = data.image;
                            })
                            .catch(error => {
                                console.error("Error fetching maintenance plans:", error);
                            });
                        }
                        
                    })
                }
            })  
        }

        // Ocultar dropdowns
        if (serieControl && versionControl) {
            versionControl.parentElement.addEventListener("click", () => {
                serieControl.parentElement.classList.remove("--open");
            })
            serieControl.parentElement.addEventListener("click", () => {
                versionControl.parentElement.classList.remove("--open");
            })
        }
    });
};


export function maintenanceSwiper() {
    if (document.querySelector(".maintenance_page")) {
        const swiper = new Swiper(".maintenance_swiper", {
            spaceBetween: 0,
            centeredSlides: true,
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            on: {
                slideChange: () => {
                    const activeSlide = swiper.slides[swiper.activeIndex];
                    const todoDescription = document.querySelector("#description-todo");
                    const inspectDescription = document.querySelector("#description-inspect");
                    const changeDescription = document.querySelector("#description-change");
                    const maintenancePrice = document.querySelector("#maintenance-price");

                    todoDescription.innerHTML = activeSlide.getAttribute('data-to-do');
                    inspectDescription.innerHTML = activeSlide.getAttribute('data-inspect');
                    changeDescription.innerHTML = activeSlide.getAttribute('data-change');
                    maintenancePrice.innerHTML = Number(activeSlide.getAttribute('data-price')).toLocaleString("es-CL", { maximumFractionDigits: 0 });
                }
            }
        });
    }
}
